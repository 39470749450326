import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`An experienced developer with a taste for the functional and minimal, with 15+ years of experience in programming and design. Has an eye for details, and an awareness of the whole. Will unrelentingly refactor your code, and reason changes with great verbosity.`}</p>
    <p>{`Currently working as the Director of Mellow Pty Ltd, we're making games!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      